import { client } from "api/client";
import * as actions from "modules/common/store/actions";
import { CONFIGURATION } from "utilities/configuration";
import * as utility from "utilities/utils";

function getAll(params) {
    return async (dispatch) => {
        const url = process.env.REACT_APP_QUOTE_GET_ALL;
        const quote = utility.getResponse();
        quote.loading = false;
        quote.data = null;
        return await client.post(url, params).then((response) => {

            quote.loading = true;
            response?.data.quotes.map(quote => {
                quote.isExpanded = false;
            });

            quote.data = response?.data;

            return dispatch({
                type: actions.GET_QUOTES_SUCCESS,
                payload: quote
            });
        })
            .catch((error) => {
                return dispatch({
                    type: actions.GET_QUOTES_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });

    };

}

function get(quoteId) {
    return async (dispatch) => {

        const url = process.env.REACT_APP_QUOTE_GET;
        return await client.get(`${url}/${quoteId}`).then((response) => {
            let quote = {};
            quote = response?.data;

            quote?.quoteOptions?.map((quoteOption) => {
                quoteOption.products?.map((product) => {
                    product.itineraries?.map((itinerary) => {
                        itinerary.isExpanded = false;
                    });
                });
            });

            const responseData = utility.getResponse(response);
            return dispatch({
                type: actions.GET_QUOTE_SUCCESS,
                payload: { ...responseData, data: responseData?.data?.data }
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_QUOTE_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });
    };
}


function getQuoteItinerary(params) {

    let { quoteNumber, itineraryID } = params;

    return async (dispatch) => {

        const url = process.env.REACT_APP_QUOTE_ITINERARY;

        return await client.get(`${url}/${quoteNumber}/${itineraryID}`).then((response) => {

            const responseData = utility.getResponse(response);
            return dispatch({
                type: actions.GET_QUOTE_ITINERARY_SUCCESS,
                payload: { ...responseData, data: responseData?.data?.data }
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_QUOTE_ITINERARY_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });
    };

}

function getDeviationItinerary(params) {

    let { quoteNumber, itineraryID } = params;

    return async (dispatch) => {

        const url = process.env.REACT_APP_DEVIATION_ITINERARY;

        return await client.get(`${url}/${quoteNumber}/${itineraryID}`).then((response) => {

            const responseData = utility.getResponse(response);
            return dispatch({
                type: actions.GET_QUOTE_ITINERARY_SUCCESS,
                payload: { ...responseData, data: responseData?.data?.data }
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_QUOTE_ITINERARY_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });
    };

}
function updateDeviationItinerary(params) {

    return async (dispatch) => {
        const url = process.env.REACT_APP_DEVIATION_ITINERARY;

        return await client.put(url, params).then((response) => {

            return dispatch({
                type: actions.GET_QUOTE_ITINERARY_SUCCESS,
                payload: utility.getResponse(response)
            });

        })
            .catch((error) => {

                return dispatch({
                    type: actions.GET_QUOTE_ITINERARY_FAIL,
                    payload: utility.getErrorResponse(error)
                });

            });


    };

}

function save(params, isIQMUser) {

    return async (dispatch) => {
        const url = process.env.REACT_APP_QUOTE_GET;
        let timedOut = false;

        const quote = utility.getResponse();
        quote.loading = false;
        quote.data = null;

        const timeoutId = setTimeout(() => {
            if (!isIQMUser) {
                timedOut = true;
                return dispatch({
                    type: actions.GET_QUOTE_TIMED_OUT,
                    payload: true
                }), 
                dispatch({
                    type: actions.SET_LOADER_STOP,
                    payload: false
                });
            }
        }, 2.5 * 60 * 1000);

        return await client.put(url, params, { method: "PUT" }).then((response) => {
            // if not timeout yet, not matter what response returned, we should clear out the timer
            if (!timedOut && timeoutId)
            {
                clearTimeout(timeoutId);
            }

            quote.loading = true;
            quote.data = response?.data;

            if (response?.statusCode === 200) {
                if (!timedOut) {
                    return dispatch({
                        type: actions.SET_LOADER_STOP,
                        payload: false
                    }),
                    dispatch({
                        type: actions.GET_QUOTE_SUCCESS,
                        payload: quote
                    });
                };
            } 
            else {
                if (!timedOut && timeoutId) {
                    return dispatch({
                        type: actions.SET_LOADER_START,
                        payload: false
                    }),
                    dispatch({
                        type: actions.GET_QUOTE_FAIL,
                        payload: utility.getErrorResponse(response?.ErrorInfo)
                    });
                }
            }
        })
            .catch((error) => {
                if (!timedOut && timeoutId) {
                    // if not timeout yet, we should clear up the timer in catch
                    clearTimeout(timeoutId);
                    return dispatch({
                        type: actions.SET_LOADER_START,
                        payload: false
                    }),
                    dispatch({
                        type: actions.GET_QUOTE_FAIL,
                        payload: utility.getErrorResponse(error)
                    });
                }
            });

    };

}

const quote = {
    get,
    getAll,
    save,
    getQuoteItinerary,
    getDeviationItinerary,
    updateDeviationItinerary
};

export default quote;
